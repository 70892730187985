import { Component, OnInit, ViewChild } from "@angular/core";
import {
  AlertController,
  ModalController,
  NavParams,
  Platform,
} from "@ionic/angular";
import { SimplePdfViewerComponent } from "simple-pdf-viewer";
import { PdfViewerComponent } from "../pdf-viewer/pdf-viewer.component";

@Component({
  selector: "app-doc-view",
  templateUrl: "./doc-view.component.html",
  styleUrls: ["./doc-view.component.scss"],
})
export class DocViewComponent implements OnInit {
  @ViewChild(SimplePdfViewerComponent, { static: true })
  private pdfViewer: SimplePdfViewerComponent;

  constructor(
    public alertController: AlertController,
    public modalController: ModalController,
    private navParams: NavParams,
    public platform: Platform
  ) {}

  equipArr = [];
  maintainArr = [];
  maintainGenArr = [];
  equipGenArr = [];
  isCordova = false;

  ngOnInit() {
    this.isCordova = this.platform.is("cordova");
    this.equipArr = this.navParams.data.equipArr;
    this.maintainArr = this.navParams.data.maintainArr;
    this.maintainGenArr = this.navParams.data.maintainGenArr;
    this.equipGenArr = this.navParams.data.equipGenArr;
  }

  async dismissModal(event) {
    const modal = await this.modalController.getTop();
    if (modal) {
      modal.dismiss({});
      console.log(event, "DISMISSED");
    }
  }

  // public findById(basePath?: string): Observable<Blob> {
  //   return this.httpClient.get(`${basePath}`, {responseType: 'blob'});
  // }

  async openPDFFileModal(pdfUrl, messageText, buttonText) {
    console.log("PDF URL", pdfUrl);
    console.log("Message", messageText);
    var modal;
    if (this.isCordova) {
      console.log("MOBILE VIEW");
      modal = await this.modalController.create({
        component: PdfViewerComponent,
        cssClass: "modal-fullscreen",
        showBackdrop: true,
        componentProps: {
          pdfUrl: pdfUrl,
          buttonText: buttonText,
          messageText: messageText,
        },
      });
      return await modal.present();
    } else {
      console.log("WEB VIEW");
      await this.showAlert(pdfUrl);

      // this.findById(pdfUrl)
      //   .subscribe((blob: Blob): void => {
      //     const file = new Blob([blob], {type: 'application/pdf'});
      //     const fileURL = URL.createObjectURL(file);
      //     window.open(fileURL, '_blank', 'width=1000, height=800');
      //   });
    }
  }

  async showAlert(pdfUrl) {
    console.log("SHOW ALERT");
    const alert = await this.alertController.create({
      header: "View Safety/Equipment File",
      subHeader:
        "Please download Safety/Equipment File to review.",
      buttons: [
        {
          text: "Download",
          handler: () => {
            // Create a new URL object from the pdfUrl
            const url = new URL(pdfUrl);
            
            // Check if the protocol is HTTP, and update it to HTTPS
            if (url.protocol === 'http:') {
              url.protocol = 'https:';
            }
            window.location.href = url.toString();
            return;
          },
        },
        {
          text: "Cancel",
          role: "ignore",
          handler: () => {
            return;
          },
        },
      ],
    });
    await alert.present();
  }
}

// <ion-row wrap>
//       <ion-item *ngFor="let eq of equipGenArr">
//           <ion-ripple-effect></ion-ripple-effect>
//           <ion-col>
//             <img  *ngIf="isCordova; else webClick" style="width: 50px" src="assets/imgs/ic_pdf.png" (click)="openPDFFileModal(
//             eq.isDownloaded ? eq.imagePath : eq.s3Url,
//             'n/a',
//             'Close')"/>

//           <ng-template #webClick>
//             <a href="{{eq.s3Url}}" target="_blank">
//               <img style="width: 50px" src="assets/imgs/ic_pdf.png"/>
//             </a>
//           </ng-template>

//           <p>{{eq.documentName}}</p>
//         </ion-col>
//       </ion-item>
//     </ion-row>
